var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "optional-info" },
    [
      _c("div", { staticClass: "notice" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("webview.optionalInfo.notice1")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("webview.optionalInfo.notice2")))]),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "channel-list" },
        _vm._l(_vm.channelList, function (channel) {
          return _c("li", [
            _c(
              "a",
              {
                attrs: { href: channel.url },
                on: { click: _vm.showLoadingCircle },
              },
              [
                _c("img", {
                  staticClass: "channel-logo",
                  attrs: { src: _vm.ChannelMap[channel.name].logo },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "channel-name" }, [
                  _vm._v(_vm._s(_vm.ChannelMap[channel.name].name)),
                ]),
                _vm._v(" "),
                channel.finished
                  ? _c("img", {
                      staticClass: "channel-status",
                      attrs: { src: require("./img/check_circle.png") },
                    })
                  : _c("img", {
                      staticClass: "channel-status",
                      attrs: { src: require("./img/arrow.png") },
                    }),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.allChannelAuthorized
        ? _c(
            "p",
            { staticClass: "finish", on: { click: _vm.finishAuthorization } },
            [_vm._v(_vm._s(_vm.$t("webview.optionalInfo.finish")))]
          )
        : _c("p", { staticClass: "skip", on: { click: _vm.openSkipModal } }, [
            _vm._v(_vm._s(_vm.$t("webview.optionalInfo.jump"))),
          ]),
      _vm._v(" "),
      _vm.isLoading ? _c("ec-webview-loading-circle") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }