/**
 * @author liuzhenkun
 * @date 2018/2/26-下午2:33
 * @file optional-info
 */

import WebviewInterface from 'moneycan/app/easycash-indo/webview-interface';
import Webview from 'moneycan/common/resource/easycash-indo-webview';

import ChannelMap from './constant/ChannelMap';
import skipModal from './skip-modal';

export default {
    name: 'optionalInfo',

    title: 'Data Pribadi (Pilihan)',

    data() {
        return {
            ChannelMap,
            channelList: [],
            isLoading: false
        };
    },

    methods: {
        openSkipModal() {
            const vm = this;

            vm.$modal.open(skipModal).then(() => {
                vm.finishAuthorization();
            });
        },

        finishAuthorization() {
            const vm = this;
            vm.isLoading = true;

            Webview.finishJuxinliAuthorization().then(() => {
                WebviewInterface.fillOptionalInfoCallback();
            });
        },

        showLoadingCircle() {
            const vm = this;
            vm.isLoading = true;
        }
    },

    computed: {
        allChannelAuthorized() {
            const vm = this;
            let finishedAmount = 0;

            if (!vm.channelList.length) {
                return false;
            }

            vm.channelList.forEach(channel => {
                if (channel.finished) {
                    finishedAmount += 1;
                }
            });

            return finishedAmount === vm.channelList.length;
        }
    },

    mounted() {
        const vm = this;

        const {from} = vm.$route.query;

        if (from) {
            Webview.updateJuxinliDataStatus({juxinliDatasource: from}).then(({body: {body: {datasources}}}) => {
                vm.channelList = datasources;
                vm.$router.replace(vm.$route.path);
            });

            return;
        }

        Webview.getJuxinliInfo().then(({body: {body: {datasources}}}) => {
            vm.channelList = datasources;
        });
    }
};
