/**
 * @author liuzhenkun
 * @date 2018/3/1-下午3:03
 * @file channelImgMap
 */
import facebookLogo from '../img/facebook.png';
import gojekLogo from '../img/go_jek.png';

const ChannelNameMap = {
    facebook: 'Facebook',
    gojek: 'Go-Jek'
};

export default {
    facebook: {
        name: ChannelNameMap.facebook,
        logo: facebookLogo
    },
    gojek: {
        name: ChannelNameMap.gojek,
        logo: gojekLogo
    }
};
