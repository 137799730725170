var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "skip-modal" },
    [
      _c(
        "ec-modal",
        { attrs: { visible: _vm.visible }, on: { close: _vm.cancel } },
        [
          _c("div", { staticClass: "skip-modal-body" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("webview.optionalInfo.modalNotice"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn confirm",
              attrs: { slot: "actions" },
              on: { click: _vm.confirm },
              slot: "actions",
            },
            [_vm._v(_vm._s(_vm.$t("webview.optionalInfo.affirmJump")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn cancel",
              attrs: { slot: "actions" },
              on: { click: _vm.cancel },
              slot: "actions",
            },
            [_vm._v(_vm._s(_vm.$t("webview.optionalInfo.continueFulling")))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }