/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 13:28:52
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2018-07-10 17:27:06
 */

import {YqgWebviewInterface} from 'ssr-common/util/webview/yqg-webview-interface';

class EasyCashIndoWebviewInterface extends YqgWebviewInterface {

    fillOptionalInfoCallback() {
        this.callHandler('fillOptionalInfoCallback');
    }

}

export default new EasyCashIndoWebviewInterface();
