/**
 * @author zhenkunliu
 * @email zhenkunliu@yangqianguan.com
 * @date 2018-02-26 15:04:13.160
 * @desc generated by yqg-cli@0.0.16
 */

export default {
    name: 'SkipModal',

    data() {
        return {
            visible: false
        };
    },

    methods: {
        cancel() {
            const vm = this;
            vm.visible = false;
            vm.reject();
        },

        confirm() {
            const vm = this;
            vm.visible = false;
            vm.resolve();
        }
    }
};
